export class WebApi {
    static get GET() {
        return {
            Draft: DraftMethodsGET,
            Authentication: AuthenticationMethodsGET,
            CauseCodes: CauseCodesMethodsGET,
            Command: CommandMethodsGET,
            DedicatedLocation: DedicatedLocationMethodsGET,
            IngoingOrder: IngoingOrderMethodsGET,
            IntegrationMonitor: IntegrationMonitorMethodsGET,
            Inventory: InventoryMethodsGET,
            ErpClassification: ErpClassificationMethodsGET,
            Job: JobMethodsGET,
            LoadCarrierType: LoadCarrierTypeMethodsGET,
            Localization: LocalizationMethodsGET,
            Location: LocationMethodsGET,
            LocationRole: LocationRoleMethodsGET,
            LocationRoleGroup: LocationRoleGroupMethodsGET,
            Lookup: LookupMethodsGET,
            Material: MaterialMethodsGET,
            Operation: OperationMethodsGET,
            OutgoingOrder: OutgoingOrderMethodsGET,
            Printer: PrinterMethodsGET,
            Process: null,
            ProcessDefinition: ProcessDefinitionMethodsGET,
            Report: ReportDataMethodsGET,
            LocationRoles: LocationRoles,
            Stock: StockMethodsGET,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsGET,
            Task: TaskMethodsGET,
            User: UserMethodsGET,
            UserRole: UserRoleMethodsGET,
            UserSession: UseSessionMethodsGET,
            Supplier: SupplierMethodsGET,
            UnDraftedSupplierMaterial: UnDraftedSupplierMaterialMethodGET,
            DraftSupplier: DraftSupplierMethodGET,
            DraftNumber: DraftNumberMethodGET,
            DraftLines: DraftLinesMethodGET,
            DraftLinesById: DraftLinesIdMethodGET,
            OrderNumber: OrderNumberMethodGET,
            OpenIdFeature: OpenIdFeatureEnableMethodGET,
            Settings : SettingsMethodsGET,
            SalesOrderDraft: SalesOrderDraftMethodGET,
        };
    }

    static get PATCH() {
        return {
            IngoingOrder: IngoingOrderMethodsPATCH
        };
    }

    static get POST() {
        return {
            Authentication: AuthenticationMethodsPOST,
            DedicatedLocation: DedicatedLocationMethodsPOST,
            Job: JobMethodsPOST,
            Location: LocationMethodsPOST,
            OutgoingOrder: OutgoingOrderMethodsPOST,
            Printer: PrinterMethodsPOST,
            Seeding: SeedingMethodsPOST,
            ProductLineForSupplier: ProductLineForSupplierMethodPOST,
            Tools: ToolsMethodsPOST,
            UserChoice: UserChoiceMethodsPOST,
            UserSession: UserSessionMethodsPOST,
            Supplier: SupplierMethodsPOST,
            Draft: DraftMethodsPOST,
            Material: MaterialMethodPOST,
            SalesOrderDraft: SalesOrderDraftMethodPOST,
            SalesOrder: SalesOrderMethodPOST,
            CompanyInfo: CompanyInfoMethodPost,
            SplitOrder: OperationMethodsPost,
            CreateRole: UserRoleMethodsPOST,
            User: UserMethodsPOST,
            Settings: SettingsMethodsPOST,
        };
    }

    static get PUT() {
        return {
            CauseCodes: CauseCodesMethodsPUT,
            DedicatedLocation: DedicatedLocationMethodsPUT,
            Inventory: InventoryMethodsPUT,
            Job: JobMethodsPUT,
            Location: LocationMethodsPUT,
            OutgoingOrder: OutgoingOrderMethodsPUT,
            Printer: PrinterMethodsPUT,
            Process: ProcessMethodsPUT,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsPUT,
            Task: TaskMethodsPUT,
            User: UserMethodsPUT,
            Supplier: SupplierMethodsPUT,
            SalesOrderDraft: SalesOrderDraftMethodPUT,
            UpdateDraft: DraftMethodsPUT,
            UpdateMaterial: MaterialMethodsPUT,
            UpdateRole: UserRoleMethodsPUT,
        };
    }

    static get DELETE() {
        return {
            CauseCodes: CauseCodesMethodsDELETE,
            DedicatedLocation: DedicatedLocationMethodsDELETE,
            Location: LocationMethodsDELETE,
            Printer: PrinterMethodsDELETE,
            StockAttributeConfiguration: StockAttributeConfigurationMethodsDELETE,
            User: UserMethodsDELETE,
            Supplier: SupplierMethodsDELETE,
            Draft: DraftMethodsDELETE,
            SalesOrderDraft: SalesOrderDraftMethodDELETE,
            Role: UserRoleMethodsDELETE,
            Users: UserMethodsDELETE,

        };
    }
}

enum AuthenticationMethodsGET {
    GenerateOidcUri = '/api/Authentication/generateOidcUri?businessUnitName={businessUnitName}&clientId=WebClient&isApportAccount={isApportAccount}',
    SignalR = '/api/Authentication/signalr/viewHub'
}

enum AuthenticationMethodsPOST {
    User = '/api/Authentication/user'
}

enum CauseCodesMethodsGET {
    Header = '/api/CauseCode/Header'
}
enum CauseCodesMethodsPUT {
    Update = '/api/CauseCode'
}
enum CauseCodesMethodsDELETE {
    Remove = '/api/CauseCode'
}

enum CommandMethodsGET {
    TransationStatus = '/api/Command/transaction/status'
}

enum DedicatedLocationMethodsGET {
    All = '/api/DedicatedLocation/all',
    Single = '/api/DedicatedLocation'
}
enum DedicatedLocationMethodsPOST {
    Create = '/api/DedicatedLocation'
}
enum DedicatedLocationMethodsPUT {
    Update = '/api/DedicatedLocation'
}
enum DedicatedLocationMethodsDELETE {
    Delete = '/api/DedicatedLocation'
}

enum SupplierMethodsPUT {
    Update = '/api/Supplier',
    AssociateMaterialsToSupplier = '/api/Supplier/AssociateMaterialsToSupplier'
}

enum DraftMethodsGET {
    GetDraftNumber = '/api/Draft/GetDraftNo',
    GetDraftLines = '/api/Draft/GetDraftLines',
    Detail = '/api/Draft/Detail/',
    Supplier = '/api/Draft/GetAllSupplier',
    GetOrderNumber = '/api/Draft/GetOrderNo'
}

enum DraftMethodsPOST {
    Get = '/api/Draft/GetUnsavedDraftLines',
    CreatePurchaseOrder = '/api/Draft/CreatePO',
    Create = '/api/Draft'
}

enum DraftMethodsPUT {
    Update = '/api/Draft'
}


enum ErpClassificationMethodsGET {
    Header = '/api/ErpClassification/header'
}

enum IngoingOrderMethodsGET {
    Description = '/api/IngoingOrder/description'
}
enum IngoingOrderMethodsPATCH {
    Complete = '/api/IngoingOrder/complete'
}

enum InventoryMethodsGET {
    Lines = '/api/Inventory/lines'
}
enum InventoryMethodsPUT {
    Accept = '/api/Inventory/lines/accept',
    Cancel = '/api/Inventory/lines/cancel',
    CancelInventory = '/api/Inventory/cancelInventory',
    ApproveInventory = '/api/Inventory/approveInventory',
}

enum IntegrationMonitorMethodsGET {
    Data = '/api/IntegrationData',
    TypeFilters = '/api/IntegrationMonitor/Filters/IntegrationTypes',
    ExternalSystemNames = '/api/IntegrationMonitor/Filters/ExternalSystemNames',
    ExternalEndpointNames = '/api/IntegrationMonitor/Filters/ExternalEndpointNames',
    MainRetrievalEndpointNames = '/api/IntegrationMonitor/Filters/MainRetrievalEndpointNames',
    EventSubscriptionNames = '/api/IntegrationMonitor/Filters/EventSubscriptionNames',
    SortOrder = '/api/IntegrationMonitor/Enum/SortOrder',
    ResultLevel = '/api/IntegrationMonitor/Enum/IntegrationResultLevelFilter',
    InitiatedEnum = '/api/IntegrationMonitor/Enum/Initiated'
}

enum JobMethodsGET {
    Description = '/api/Job/description',
    Instructions = '/api/Job/instructions',
}
enum JobMethodsPOST {
    CreateInventoryJobs = '/api/Job/createInventoryJobs',
    CreateAndStartFromStocks = '/api/Job/createAndStartFromStocks',
    CreateAndAssignJobFromTasks = '/api/Job/createAndAssignJobFromTasks',
    CreateRecountInventoryJobs = '/api/Job/createRecountInventoryJobs',
}
enum JobMethodsPUT {
    Assign = '/api/Job/assign',
    UnAssign = '/api/Job/unassign',
    AssignOperationsToUser = '/api/Job/assignOperationsToUser',
    TryDeviate = '/api/Job/tryDeviateOnInstruction',
    TryFinish = '/api/Job/tryFinish',
    End = '/api/Job/end',
}

enum LocalizationMethodsGET {
    Languages = '/api/Localization/languages',
    NestedTranslations = '/api/Localization/translationsnested?clientName=Cockpit',
    Translations = '/api/Localization/translations?clientName=Cockpit'
}

enum LocationMethodsGET {
    Header = '/api/Location/header',
    Description = '/api/Location/description'
}
enum LocationMethodsPOST {
    Create = '/api/Location'
}
enum LocationMethodsPUT {
    Update = '/api/Location'
}
enum LocationMethodsDELETE {
    ById = '/api/Location'
}

enum LocationRoleMethodsGET {
    Header = '/api/LocationRole/header'
}
enum LocationRoleGroupMethodsGET {
    Header = '/api/LocationRoleGroup/header',
    Detail = '/api/LocationRoleGroup/detail'
}

enum LookupMethodsGET {
    All = '/api/Lookup',
    ByType = '/api/Lookup/{lookupType}'
}

enum LoadCarrierTypeMethodsGET {
    Header = '/api/LoadCarrierType/header'
}

enum MaterialMethodsGET {
    BOM = '/api/Material/bom/{materialNumber}',
    Description = '/api/Material/description',
    Search = '/api/Material/search',
    SearchIndex = '/api/Material/description/searchIndex',
    Product = '/api/Material/GetUnassociatedMaterials',
    UndraftedSupplierMaterials = '/api/Material/GetUndraftedSupplierMaterials'
}

enum MaterialMethodsPUT {
    Update = '/api/material/UpdateMaterial'
}

enum OperationMethodsGET {
    Description = '/api/Operation/description'
}

enum CompanyInfoMethodPost {
    Post = '/api/companyinfo'
}

enum OperationMethodsPost {
    Update = '/api/Operation/SplitOrder'
}

enum OutgoingOrderMethodsGET {
    Description = '/api/OutgoingOrder/description',
    OrderType = '/api/OutgoingOrder/orderType',
    DeliveryType = '/api/OutgoingOrder/deliveryType',
    CustomerInfomation = '/api/OutgoingOrder/getCustomerInformation',
}
enum OutgoingOrderMethodsPOST {
    CancelSalesOrder = '/api/OutgoingOrder/cancelsalesorder'
}
enum OutgoingOrderMethodsPUT {
    PickedStock = '/api/OutgoingOrder/pickedStock',
    UnPrepaareStock = '/api/OutgoingOrder/unprepare',
    OrderlineQuantity = '/api/OutgoingOrder/orderline/quantity',
    DeliveryPriorityDate = '/api/OutgoingOrder/DeliveryPriority'
}

enum ProcessMethodsPUT {
    Prepare = '/api/Process/prepare',
    UnPrepare = '/api/Process/unprepare',
    CancelOrderInProcesses = '/api/Process/cancelOrderInProcesses',
    ExecutionPriority = '/api/Process/executionPriority'
}

enum PrinterMethodsGET {
    Description = '/api/Printer/description'
}
enum PrinterMethodsPOST {
    Create = '/api/Printer'
}
enum PrinterMethodsPUT {
    Update = '/api/Printer'
}
enum PrinterMethodsDELETE {
    ById = '/api/Printer'
}

enum ProcessDefinitionMethodsGET{
    ActiveProcessDefinitionCategories = 'api/ProcessDefinition/GetActiveProcessDefinitionCategories'
}

enum LocationRoles{
    Get="/api/locationRole/header?ignoreSystemLocationRoles=false&&onlyStorageLocationRoles=true"
}

enum ReportDataMethodsGET {
    All = '/api/ReportData',
    MaterialSummary = 'api/ReportData/materialsummary',
    MaterialTransactions = 'api/ReportData/report/materialTransactionOverview',
    SlowMovingStock = 'api/ReportData/report/slowMovingMaterials'
}

enum SeedingMethodsPOST {
    CockpitUserSettings = '/api/Seeding/defaultUserSettings?owner={owner}&clientId={clientId}&canCustomerModify=true&canUserModify=true&scope=BusinessUnit',
    Excel = '/api/Seeding/excel',
    Grid_FactorySettings = '/api/Seeding/defaultUserSettings?owner=GridSettings&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope=Factory',
    Grid_BusinessUnitSettings = '/api/Seeding/defaultUserSettings?owner=GridSettings&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope=BusinessUnit',
    KPI_FactorySettings = '/api/Seeding/defaultUserSettings?owner=KpiSettings&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope=Factory',
    KPI_BusinessUnitSettings = '/api/Seeding/defaultUserSettings?owner=KpiSettings&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope=BusinessUnit',
    Grid_ScopeUnitSettings = '/api/Seeding/defaultUserSettings?owner={owner}&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope={scope}',
    Grid_UserSettings = '/api/Seeding/defaultUserSettings?owner=GridSettings&clientId=Cockpit&canCustomerModify=true&canUserModify=true&scope=User',
}

enum StockMethodsGET {
    Description = '/api/Stock/description',
    OutgoingReservationsById = '/api/Stock/outgoingReservations'
}

enum StockAttributeConfigurationMethodsGET {
    Description = '/api/StockAttributeConfiguration/description',
    EnumOptions = '/api/StockAttributeConfiguration/EnumOptions'
}
enum StockAttributeConfigurationMethodsPUT {
    Create = '/api/StockAttributeConfiguration',
    Update = '/api/StockAttributeConfiguration',
}
enum StockAttributeConfigurationMethodsDELETE {
    ById = '/api/StockAttributeConfiguration/{id}',
}


enum TaskMethodsGET {
    Description = '/api/Task/description'
}
enum TaskMethodsPUT {
    Cancel = '/api/Task/cancel'
}

enum ToolsMethodsPOST {
    UpdateOperationViewData = '/api/Tools/UpdateOperationViewData'
}

enum UserMethodsGET {
    Active = '/api/User/active',
    Detail = '/api/User/detail',
    SystemSettings = 'api/User/settings?clientId=Backend',
    TerminalSettings = 'api/User/settings?clientId=MobileAndroid',
    CockpitSettings = 'api/User/settings?clientId=Cockpit',
    GridSettings = '/api/User/settings?clientId=Cockpit&owner=GridSettings',
    HistoryPopupSettings = '/api/User/settings?owner=InventoryLineHistory&clientId=Cockpit',
    SignalRSettings = '/api/User/settings?clientId=AppSettings&Owner=SignalR',
    MenuSettings = '/api/User/settings?owner=MenuSettings&clientId=Cockpit',
    ReservationPopupSettings = '/api/User/settings?owner=StockReservations&clientId=Cockpit',
    RoutesSettings = '/api/User/settings?owner=MenuSettings&clientId=Cockpit&key=RouteConfigs',
    Description = '/api/User/Description',
    Summary = '/api/User/summary',
}
enum UserMethodsPOST {
    ResetPassword = '/api/User/resetPassword',
    Create = '/api/User'
}

enum UserMethodsPUT {
    Settings = '/api/User/settings',
    SettingsForAll ='/api/User/settingsForAll',
    ChangePassword = '/api/User/changePassword',
    Update = '/api/User',
    ChangePasswordDuringLogin = '/api/User/password',
    ChangeDefaultLanguage = '/api/User/changeDefaultLanguage',
    UpdatePicture = '/api/User/updatePicture',
    Deactivate = '/api/User/active',
}

enum UserMethodsDELETE {
    Settings = '/api/User/settings',
    Delete = '/api/User'
}

enum UserChoiceMethodsPOST {
    Perform = '/api/UserChoice/perform'
}

enum UserRoleMethodsGET {
    Header = '/api/UserRole/header',
    Description = '/api/UserRole/description',
    Detail = '/api/UserRole/detail',
}

enum UserRoleMethodsPOST {
    Create = '/api/UserRole'
}

enum UserRoleMethodsPUT {
    Update = '/api/UserRole'
}

enum UserRoleMethodsDELETE {
    Delete = '/api/UserRole'
}

enum UseSessionMethodsGET {
}

enum UserSessionMethodsPOST {
    HeartBeat = '/api/UserSession/heartbeat',
}

enum SupplierMethodsGET {
    Header = '/api/Supplier/header',
    Description = '/api/Supplier/description'
}

enum SupplierMethodsPOST {
    Create = '/api/Supplier'
}

enum SupplierMethodsDELETE {
    Delete = '/api/Supplier'
}

enum ProductLineForSupplierMethodPOST {
    Product = '/api/Material/GetMaterialsForAssociations'
}

enum DraftSupplierMethodGET {
    Supplier = '/api/Draft/GetAllSupplier'
}

enum DraftNumberMethodGET {
    Get = '/api/Draft/GetDraftNo'
}

enum DraftLinesMethodGET {
    Get = '/api/Draft/GetDraftLines'
}

enum DraftLinesIdMethodGET {
    Detail = '/api/Draft/Detail/'
}

enum DraftMethodsDELETE {
    Delete = '/api/Draft'
}

enum UpdateMaterialMethodsPUT {
    Update = '/api/material/UpdateMaterial'
}

enum CreatePurchaseOrderMethodsPOST {
    Create = '/api/Draft/CreatePO'
}

enum OrderNumberMethodGET {
    Get = '/api/Draft/GetOrderNo'
}

enum UnDraftedSupplierMaterialMethodGET {
    Product = '/api/Material/GetUndraftedSupplierMaterials'
}

enum OpenIdFeatureEnableMethodGET {
    Get = '/api/OpenId/isEnabled?businessUnitName={businessUnitName}'
}

enum MaterialMethodPOST {
    Get = '/api/Material/Search'
}

enum SalesOrderDraftMethodGET {
    SODraftNumber = '/api/SalesOrderDraft/GetSalesOrderDraftNo',
    SalesOrderNumber = '/api/SalesOrderDraft/GetSalesOrderNo',
    SODraftById = '/api/SalesOrderDraft/Detail',

}

enum SalesOrderDraftMethodPOST {
    Create = '/api/SalesOrderDraft'
}

enum SalesOrderDraftMethodPUT {
    Update = '/api/SalesOrderDraft'
}

enum SalesOrderMethodPOST {
    Create = '/api/SalesOrderDraft/CreateSalesOrder',
    Update ='/api/SalesOrderDraft/CreateSalesOrderFromDraft',
    Validate = '/api/SalesOrderDraft/Validate'
}

enum SettingsMethodsGET {
    AvailableCodeSwitches = '/api/Settings/AvailableCodeSwitches',
    EnabledCodeSwitches = '/api/Settings/EnabledCodeSwitches',
    AvailableFeatureFlags = '/api/Settings/AvailableFeatureFlags',
    EnabledFeatureFlags = '/api/Settings/EnabledFeatureFlags',
}

enum SettingsMethodsPOST {
    EnabledCodeSwitches = '/api/Settings/EnabledCodeSwitches',
    EnabledFeatureFlags = '/api/Settings/EnabledFeatureFlags', 
}

enum SalesOrderDraftMethodDELETE {
    Delete = '/api/SalesOrderDraft',
}